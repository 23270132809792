var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-4 px-0 px-xl-4"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mb-3"},[_c('b-button',{attrs:{"to":{ name: 'ListManagerUpload' }}},[_vm._v("UPLOAD")])],1),_c('b-row',{staticClass:"mb-3"},[_c('h1',[_vm._v("DEMO NON FUNZIONANTE")])]),_c('b-row',{staticClass:"mb-3"},[_vm._v(" pagina per riepilogo marche non aggiornate filtrate per articoli visibili ")]),_c('b-row',{staticClass:"mb-3"},[_c('b-card',{staticClass:"w-100"},[_c('b-form-input',{attrs:{"id":"filter-input","type":"search","placeholder":"Cerca"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items2,"fields":_vm.fields2,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(last_update)",fn:function(data){return [_c('b-badge',{attrs:{"variant":_vm.checkExpiredDate(data.item.last_update) ? 'danger' : 'success'}},[(!data.item.last_update)?_c('span',[_vm._v("NON ESEGUITO")]):_c('span',[_vm._v(_vm._s(data.item.last_update))])])]}},{key:"cell(time_from_last_update)",fn:function(data){return [_c('span',{staticClass:"font-weight-bold font-italic",class:_vm.checkExpiredDate(data.item.last_update)
                  ? 'text-danger'
                  : 'text-success'},[_vm._v(_vm._s(_vm.getTimeToUpdated(data.item.last_update)))])]}},{key:"cell(block_alert)",fn:function(data){return [_c('b-form-checkbox',{staticClass:"d-inline align-middle",attrs:{"disabled":_vm.showBlockAlertSpinner[data.index],"switch":""},on:{"change":function($event){return _vm.toggleBlockAlert(data.index)}}}),_c('b-spinner',{style:(_vm.showBlockAlertSpinner[data.index] ? '' : 'visibility: hidden'),attrs:{"small":"","variant":"primary"}})]}},{key:"cell(configuration)",fn:function(data){return [_c('b-link',{attrs:{"to":'list-manager/configuration/' + data.item.brandId}},[_c('font-awesome-icon',{class:{
                  'text-success': data.item.configuration,
                  'text-danger': !data.item.configuration
                },attrs:{"title":data.item.configuration ? 'modifica' : 'aggiungi',"icon":data.item.configuration ? 'check-circle' : 'times-circle',"size":"lg"}})],1)]}},{key:"cell(file)",fn:function(data){return [_c('b-link',{attrs:{"to":'list-manager/upload/' + data.item.brandId}},[_c('font-awesome-icon',{class:{
                  'text-success': data.item.file,
                  'text-danger': !data.item.file
                },attrs:{"title":"aggiungi","icon":data.item.file ? 'check-circle' : 'times-circle',"size":"lg"}})],1)]}},{key:"cell(schedule)",fn:function(data){return [_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.repeat(data.index)}}},[_c('font-awesome-icon',{attrs:{"icon":"sync","size":"sm"}}),_vm._v(" Ripeti")],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }