<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-button :to="{ name: 'ListManagerUpload' }">UPLOAD</b-button>
      </b-row>
      <b-row class="mb-3">
        <h1>DEMO NON FUNZIONANTE</h1>
      </b-row>
      <b-row class="mb-3">
        pagina per riepilogo marche non aggiornate filtrate per articoli
        visibili
      </b-row>
      <b-row class="mb-3">
        <b-card class="w-100">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Cerca"
          ></b-form-input>
          <b-table
            striped
            hover
            :items="items2"
            :fields="fields2"
            :filter="filter"
          >
            <template #cell(last_update)="data">
              <b-badge
                :variant="
                  checkExpiredDate(data.item.last_update) ? 'danger' : 'success'
                "
                ><span v-if="!data.item.last_update">NON ESEGUITO</span
                ><span v-else>{{ data.item.last_update }}</span></b-badge
              >
            </template>
            <template #cell(time_from_last_update)="data">
              <span
                class="font-weight-bold font-italic"
                :class="
                  checkExpiredDate(data.item.last_update)
                    ? 'text-danger'
                    : 'text-success'
                "
                >{{ getTimeToUpdated(data.item.last_update) }}</span
              >
            </template>
            <template #cell(block_alert)="data">
              <b-form-checkbox
                @change="toggleBlockAlert(data.index)"
                class="d-inline align-middle"
                :disabled="showBlockAlertSpinner[data.index]"
                switch
              /><b-spinner
                :style="
                  showBlockAlertSpinner[data.index] ? '' : 'visibility: hidden'
                "
                small
                variant="primary"
              />
            </template>
            <template #cell(configuration)="data">
              <b-link :to="'list-manager/configuration/' + data.item.brandId">
                <font-awesome-icon
                  :title="data.item.configuration ? 'modifica' : 'aggiungi'"
                  :class="{
                    'text-success': data.item.configuration,
                    'text-danger': !data.item.configuration
                  }"
                  :icon="
                    data.item.configuration ? 'check-circle' : 'times-circle'
                  "
                  size="lg"
              /></b-link>
            </template>
            <template #cell(file)="data">
              <b-link :to="'list-manager/upload/' + data.item.brandId">
                <font-awesome-icon
                  title="aggiungi"
                  :class="{
                    'text-success': data.item.file,
                    'text-danger': !data.item.file
                  }"
                  :icon="data.item.file ? 'check-circle' : 'times-circle'"
                  size="lg"
              /></b-link>
              <!--<b-button variant="success" size="sm"
                ><font-awesome-icon icon="pen" size="sm" /> Modifica</b-button
              >
              <b-button variant="danger" size="sm"
                ><font-awesome-icon icon="plus" size="sm" /> Aggiungi</b-button
              >-->
            </template>
            <template #cell(schedule)="data">
              <b-button variant="primary" size="sm" @click="repeat(data.index)"
                ><font-awesome-icon icon="sync" size="sm" /> Ripeti</b-button
              >
            </template>
          </b-table>
        </b-card>
      </b-row>
      <!--<b-row class="mb-3">
        lista uploadati con nome file ed estenzione
      </b-row>
      <b-row class="mb-3">
        <b-card class="w-100">
          <b-table striped hover :fields="fields" :items="items">
            <template #cell(priceIncrease)="data">
              <b
                :class="{
                  'text-success': data.item.priceIncrease < 0,
                  'text-danger': data.item.priceIncrease > 0
                }"
                ><font-awesome-icon
                  v-if="data.item.priceIncrease > 0"
                  icon="arrow-up"
                /><font-awesome-icon
                  v-if="data.item.priceIncrease < 0"
                  icon="arrow-down"
                />
                {{ data.item.priceIncrease }} %</b
              >
            </template>
            <template #cell(updated)="data">
              <b-badge :variant="data.item.updated ? 'success' : 'danger'"
                ><span v-if="!data.item.updated">NON ESEGUITO</span
                ><span v-else>{{ data.item.updated }}</span></b-badge
              >
            </template>
            <template #cell(error)="data">
              <div v-if="data.item.error">
                <b-link
                  :disabled="!data.item.error.href"
                  :to="data.item.error.href"
                >
                  <span
                    :class="{
                      'text-danger': data.item.error.type === 'danger'
                    }"
                    >{{ data.item.error.text }}</span
                  >
                </b-link>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-row>-->
    </b-container>
  </section>
</template>

<script>
export default {
  name: "ListManager",
  data() {
    return {
      file: null,
      fields: [
        { key: "date", label: "Data upload" },
        { key: "brand", label: "Brand" },
        { key: "filename", label: "Nome file" },
        { key: "priceIncrease", label: "Incremento prezzo" },
        { key: "updated", label: "Aggiornamento" },
        { key: "error", label: "Errore" }
      ],
      items: [
        {
          date: "2022-03-14 23:59:59",
          brand: "Roco",
          filename: "Auhagen_Stammdaten_2021.dat",
          priceIncrease: 8,
          updated: null,
          error: {
            type: "danger",
            text: "Manca file configurazione",
            href: "list-manager/configuration/31"
          }
        },
        {
          date: "2022-03-14 23:59:59",
          brand: "Rivarossi",
          filename: "Auhagen_Stammdaten_2020.dat",
          priceIncrease: 3,
          updated: "2022-03-14 23:59:59",
          message: {}
        },
        {
          date: "2022-03-14 23:59:59",
          brand: "Lima",
          filename: "Auhagen_Stammdaten_2019.dat",
          priceIncrease: 2,
          updated: "2022-03-14 23:59:59",
          message: {}
        },
        {
          date: "2022-03-14 23:59:59",
          brand: "Roco",
          filename: "Auhagen_Stammdaten_2018.dat",
          priceIncrease: 0,
          updated: "2022-03-14 23:59:59",
          message: {}
        }
      ],
      brandId: null,
      brandIdFilter: null,
      brandList: [],
      fields2: [
        { key: "brand", label: "Produttore" },
        { key: "last_update", label: "Ultimo update" },
        { key: "time_from_last_update", label: "Tempo trascorso" },
        { key: "block_alert", label: "Blocca avviso" },
        { key: "configuration", label: "Configurazione" },
        { key: "file", label: "File" },
        { key: "schedule", label: "Schedula" }
      ],
      items2: [
        {
          brand: "Roco",
          brandId: 30,
          last_update: "2018-07-14 23:59:59",
          block_alert: true,
          configuration: true,
          file: false
        },
        {
          brand: "Roco",
          brandId: 31,
          last_update: "2022-03-14 23:59:59",
          block_alert: false,
          configuration: false,
          file: true
        },
        {
          brand: "Roco",
          brandId: 32,
          last_update: "2022-03-14 23:59:59",
          block_alert: true,
          configuration: true,
          file: true
        },
        {
          brand: "Roco",
          brandId: 33,
          last_update: "2021-03-14 23:59:59",
          block_alert: false,
          configuration: true,
          file: true
        },
        {
          brand: "Roco",
          brandId: 34,
          last_update: "2022-01-26 23:59:59",
          block_alert: true,
          configuration: true,
          file: true
        }
      ],
      showBlockAlertSpinner: [],
      filter: null
    };
  },
  mounted() {
    this.getBrand();
  },
  methods: {
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand`)
        .then(response => {
          this.brandList = response.data;
        });
    },
    uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("brandId", this.brandId);
      //TODO convalidate file
      this.$http
        .post(
          `${process.env.VUE_APP_API_ENDPOINT}list-manager/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(function() {
          console.log("SUCCESS!!");
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    },
    toggleBlockAlert(index) {
      this.showBlockAlertSpinner[index] = true;
      //TODO add post for edit block alert
      this.items2[index].block_alert = !this.items2[index].block_alert;
      //this.showBlockAlertSpinner[index] = false;
    },
    checkExpiredDate(datetime, year = 1) {
      if (!datetime) return null;
      let date = new Date(datetime.replace(" ", "T"));
      let now = new Date();
      return (
        new Date(now.getFullYear() - year, now.getMonth(), now.getDate()) > date
      );
    },
    getTimeToUpdated(datetime) {
      const diff_date = new Date() - Date.parse(datetime);
      const num_years = diff_date / 31536000000;
      const num_months = (diff_date % 31536000000) / 2628000000;
      const num_days = ((diff_date % 31536000000) % 2628000000) / 86400000;
      const years = Math.floor(num_years);
      const months = Math.floor(num_months);
      const days = Math.floor(num_days);
      let result = "";
      if (years > 0) result += years + " " + this.$tc("year", years) + ", ";
      if (months > 0) result += months + " " + this.$tc("month", months) + ", ";
      if (days > 0) result += days + " " + this.$tc("day", days);
      return result.replace(/,\s*$/, "");
    },
    repeat(index) {
      console.log(index);
      //TODO post for add repeat
    }
  }
};
</script>

<style>
a.disabled {
  pointer-events: none;
}
</style>
