export const listManager = {
  data() {
    return {
      brandList: []
    };
  },
  methods: {
    getBrandName(brandId) {
      return this.brandList.find(el => el.id === brandId)?.name;
    },
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand`)
        .then(response => {
          this.brandList = response.data;
        });
    }
  }
};