<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-card title="Upload file" class="w-100">
          <b-form-row class="mb-3">
            <b-form-file
              v-model="file"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".xls, .xlsx, .dat, .txt, .csv"
            />
          </b-form-row>
          <b-form-row class="mb-3">
            <b-form-select v-model="brandId">
              <b-form-select-option :value="null" disabled
                >Scegli il produttore
              </b-form-select-option>
              <b-form-select-option
                v-for="brand in brandList"
                :value="brand.id"
                :key="brand.id"
                >{{ brand.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-row>
          <b-form-row class="float-right">
            <b-button
              variant="primary"
              @click="add"
              :disabled="!file || !brandId"
            >
              <font-awesome-icon icon="upload" />
              Upload
            </b-button>
          </b-form-row>
        </b-card>
      </b-row>
      <b-row class="mb-3">
        <b-card class="w-100">
          <b-row class="mb-3">
            <b-col>
              <b-form-select v-model="brandIdFilter">
                <b-form-select-option :value="null" disabled
                  >Filtra per produttore
                </b-form-select-option>
                <b-form-select-option
                  v-for="brand in brandList"
                  :value="{ id: brand.id, name: brand.name }"
                  :key="brand.id"
                  >{{ brand.name }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col>
              <div
                v-if="brandIdFilter"
                @click="brandIdFilter = null"
                class="d-inline-block"
                style="font-size: 1.5rem;"
              >
                <b-form-tag class="mr-1">{{ brandIdFilter.name }}</b-form-tag>
              </div>
            </b-col>
          </b-row>
          <b-table striped hover :fields="fields" :items="items">
            <template #cell(brandId)="data"
              >{{ getBrandName(data.item.brandId) }}
            </template>
            <template #cell(priceIncreasePercentage)="data">
              <b
                v-if="data.item.priceIncreasePercentage"
                :class="{
                  'text-success': data.item.priceIncreasePercentage < 0,
                  'text-danger': data.item.priceIncreasePercentage > 0
                }"
              >
                <font-awesome-icon
                  v-if="data.item.priceIncreasePercentage > 0"
                  icon="arrow-up"
                />
                <font-awesome-icon
                  v-if="data.item.priceIncreasePercentage < 0"
                  icon="arrow-down"
                />
                {{ data.item.priceIncreasePercentage }} %</b
              >
            </template>
            <template #cell(updated)="data">
              <b-badge :variant="data.item.synchDate ? 'success' : 'danger'"
                ><span v-if="data.item.synchDate">{{
                  data.item.synchDate
                }}</span></b-badge
              >
            </template>
            <template #cell(configuration)="data">
              <div class="text-right">
                <b-link :to="{ name: 'ListManagerConfiguration', params: { brandId: data.item.brandId } }">
                  <b-button
                    :variant="
                      getBrandConfiguration(data.item.brandId)
                        ? 'danger'
                        : 'primary'
                    "
                    size="sm"
                    >{{
                      getBrandConfiguration(data.item.brandId)
                        ? "Aggiungi"
                        : "Modifica"
                    }}</b-button
                  >
                </b-link>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { listManager } from "@/mixins/listManager";

export default {
  name: "ListManagerUpload",
  mixins: [listManager],
  data() {
    return {
      file: null,
      fields: [
        { key: "createDate", label: "Data upload" },
        { key: "brandId", label: "Brand" },
        { key: "filename", label: "Nome file" },
        { key: "priceIncreasePercentage", label: "Incremento prezzo" },
        { key: "synchDate", label: "Data Sincronizzazione" },
        { key: "updated", label: "Articoli aggiornati" },
        { key: "configuration", label: "Configurazione" }
      ],
      items: [],
      brandId: null,
      brandIdFilter: null,
      showBlockAlertSpinner: [],
      filter: null,
      configuration: []
    };
  },
  watch: {
    "file.name"(value) {
      this.brandIdAutoSelect(value);
    }
  },
  mounted() {
    this.getBrand();
    this.getAllConfiguration();
    this.get();
  },
  methods: {
    brandIdAutoSelect(filename) {
      let pointPosition = filename.lastIndexOf(".");
      filename =
        pointPosition !== -1 ? filename.substring(0, pointPosition) : filename;
      let words = filename
        .trim()
        .toLowerCase()
        .split(/[ _-]/);
      let result = this.brandList.find(object => {
        let name = object.name.toLowerCase();
        return words.some(word => name === word);
      });
      this.brandId = result.id;
    },
    getBrandConfiguration(brandId) {
      return (
        this.configuration.find(el => el.brandId === brandId) === undefined
      );
    },
    get() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}list-manager`)
        .then(response => {
          this.items = response.data;
        });
    },
    add() {
      const self = this;
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("brandId", this.brandId);
      //TODO convalidate file
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}list-manager`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function() {
          self.get();
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    },
    getAllConfiguration() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}list-manager/configuration`)
        .then(response => {
          this.configuration = response.data;
        });
    },
    toggleBlockAlert(index) {
      this.showBlockAlertSpinner[index] = true;
      //TODO add post for edit block alert
      this.items2[index].block_alert = !this.items2[index].block_alert;
      //this.showBlockAlertSpinner[index] = false;
    },
    checkExpiredDate(datetime, year = 1) {
      if (!datetime) return null;
      let date = new Date(datetime.replace(" ", "T"));
      let now = new Date();
      return (
        new Date(now.getFullYear() - year, now.getMonth(), now.getDate()) > date
      );
    },
    getTimeToUpdated(datetime) {
      const diff_date = new Date() - Date.parse(datetime);
      const num_years = diff_date / 31536000000;
      const num_months = (diff_date % 31536000000) / 2628000000;
      const num_days = ((diff_date % 31536000000) % 2628000000) / 86400000;
      const years = Math.floor(num_years);
      const months = Math.floor(num_months);
      const days = Math.floor(num_days);
      let result = "";
      if (years > 0) result += years + " " + this.$tc("year", years) + ", ";
      if (months > 0) result += months + " " + this.$tc("month", months) + ", ";
      if (days > 0) result += days + " " + this.$tc("day", days);
      return result.replace(/,\s*$/, "");
    },
    repeat(index) {
      console.log(index);
      //TODO post for add repeat
    }
  }
};
</script>

<style>
a.disabled {
  pointer-events: none;
}
</style>
